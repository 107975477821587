import React, { forwardRef } from "react";
import TrustIcon from "../../assets/About-Icons/TrustIcon";
import Versatility from "../../assets/About-Icons/Versatility";
import Expertise from "../../assets/About-Icons/Expertise";
import Reputation from "../../assets/About-Icons/Reputation";
import "./AboutUs.css";
import { Link } from "react-router-dom";

const AboutUs = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="about-us">
      <div className="about-us-text" id="about-us">
        <h1>About Us</h1>
        <p>
          A company that values growth and development of their team and
          collaborators in order to achieve sustainable growth and success. At
          Maruti Gems, we put all of our energy into creating diamonds of the
          highest calibre, improving relationships with our clients, encouraging
          continuous innovation, supporting our plan to enable retailers and
          significantly boost their bottom line, and safeguarding human welfare.
          We have been providing the diamond community with joy for more than 40
          years in every way we can, but our emphasis on Trust, Versatility,
          Expertise, and Reputation value has not altered.
        </p>
      </div>
      <div className="aboutus-four">
        <div className="about-first-row">
          <div className="about-section">
            <Link to="/trust">
              <TrustIcon />
              <h3>Trust</h3>
              <p>
                We are trusted by customers for selling diamonds of excellent
                quality, as the company is committed to providing genuine and
                ethically sourced diamonds that are carefully designed and
                graded for their brilliance and value.
              </p>
            </Link>
          </div>
          <div className="about-section">
            <Link to="/versatility">
              <Versatility />
              <h3>Versatility</h3>
              <p>
                We are a versatile diamond manufacturing company, with expertise
                in crafting diamonds of different shapes, sizes and qualities,
                to cater to diverse customer needs and preferences in the global
                market.
              </p>
            </Link>
          </div>
        </div>
        <div className="about-first-row">
          <div className="about-section">
            <Link to="/expertise">
              <Expertise />
              <h3>Expertise</h3>
              <p>
                We have expertise in diamond manufacturing, including diamond
                cutting, polishing, and grading. We use cutting-edge technology
                and skilled employees to produce high-quality diamonds that meet
                industry standards and exceed customer expectations.
              </p>
            </Link>
          </div>
          <div className="about-section">
            <Link to="/reputation">
              <Reputation />
              <h3>Reputation</h3>
              <p>
                We have a reputation for producing high-quality diamonds using
                advanced technology and ethical sourcing practices. We
                prioritize customer satisfaction and strive to maintain our
                esteemed reputation in the industry.
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AboutUs;
