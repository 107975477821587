import React, { useEffect, useRef, forwardRef } from "react";
import "./CRAFTSMANSHIPC.css";
import Diamond1 from "../../assets/Crafts/diamond1.png";
import Ruby from "../../assets/Crafts/ruby.png";
import Diamond3 from "../../assets/Crafts/precious-stone.png";
import Diamond4 from "../../assets/Crafts/diamond3.png";
import Diamond5 from "../../assets/Crafts/baguette1.png";
import { useNavigate } from "react-router-dom";

const Craftsmanship = forwardRef((props, ref) => {
  const textRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;
        const textElement = textRef.current;

        if (isIntersecting) {
          textElement.style.opacity = 1; // Show the text
        } else {
          textElement.style.opacity = 0; // Hide the text
        }
      });
    }, options);

    const textElement = textRef.current;
    if (textElement) {
      observer.observe(textElement);
    }

    // Cleanup
    return () => {
      if (textElement) {
        observer.unobserve(textElement);
      }
    };
  }, []);

  const handleLinkClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const imgs = [
    "https://pcknstg.blob.core.windows.net/hdfile/webimg/6.gif",
    "https://pcknstg.blob.core.windows.net/hdfile/webimg/1.gif",
    "https://pcknstg.blob.core.windows.net/hdfile/webimg/3.gif",
    "https://pcknstg.blob.core.windows.net/hdfile/webimg/4.gif",
    "https://pcknstg.blob.core.windows.net/hdfile/webimg/5.gif",
  ];

  return (
    <div ref={ref} className="crafts" id="craftsmanship">
      <div className="craft-videos">
        {imgs.map((img, i) => (
          <div key={i}>
            <img src={img} alt={`craft-video-${i}`} />
          </div>
        ))}
      </div>
      <div className="crafts-text" ref={textRef}>
        <h1>CRAFTSMANSHIP</h1>
        <p>
          The three criteria that our exceptionally talented, highly competent,
          and superior craftsmen adhere to when creating diamonds are
          exceptional quality, distinctive cut, and remarkable sparkle.
        </p>
      </div>
      <div className="crafts-icons">
        <img
          src={Diamond1}
          alt="icon"
          onClick={() => handleLinkClick("/rounddiamond")}
        />
        <img
          src={Ruby}
          alt="icon1"
          onClick={() => handleLinkClick("/princessdiamond")}
        />
        <img
          src={Diamond3}
          alt="icon2"
          onClick={() => handleLinkClick("/peardiamond")}
        />
        <img
          src={Diamond4}
          alt="icon3"
          onClick={() => handleLinkClick("/marquisediamond")}
        />
        <img
          src={Diamond5}
          alt="icon3"
          onClick={() => handleLinkClick("/baguettediamond")}
        />
      </div>
    </div>
  );
});

export default Craftsmanship;
