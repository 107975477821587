import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/Logo/MainLogo.png";
import { SectionsRefsContext } from "../HomePage/HomePage";

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { aboutUsRef, managementTeamRef, ourPeopleRef, craftsmanshipRef } =
    useContext(SectionsRefsContext);

  const scrollToSection = (ref) => {
    const navbarHeight = document.querySelector(".navbar").offsetHeight;
    let offsetPosition =
      ref.current.getBoundingClientRect().top + window.scrollY;

    if (window.scrollY > 0) {
      offsetPosition -= navbarHeight;
    } else {
      offsetPosition -= 2 * navbarHeight;
    }

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    // Close mobile menu after scrolling
    setIsMobileMenuOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLinkClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Close the mobile menu when any menu item is selected
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const banner = document.getElementById("banner");
    const bannerHeight = banner.offsetHeight;
    const threshold = bannerHeight * 0.3;

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsSticky(scrollTop > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const [diamondsAnchorEl, setDiamondsAnchorEl] = useState(null);
  const isDiamondsMenuOpen = Boolean(diamondsAnchorEl);

  const handleDiamondsMenuClick = (event) => {
    setDiamondsAnchorEl(event.currentTarget);
  };

  const handleDiamondsMenuClose = () => {
    setDiamondsAnchorEl(null);
    // Close the mobile menu when any menu item is selected
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <div className={`navbar-placeholder ${isSticky ? "active" : ""}`}></div>
      <nav className={`navbar ${isSticky ? "sticky" : ""}`}>
        <div className="Navbar">
          <img src={Logo} alt="Logo" />
          <div className="Menubar">
            <div
              className={`horizontal-menu ${isMobileMenuOpen ? "open" : ""}`}
            >
              <div
                className="nav-menus"
                style={{ display: "flex", gap: "20px" }}
              >
                <Button
                  id="basic-button"
                  className="cool-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    color: "#402b66 !important",
                    fontFamily: "Nunito, sans-serif !important",
                    width: "120px",
                  }}
                >
                  Products
                </Button>
                <Menu
                  id="basic-menu"
                  className="drop-down"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/products"
                    onClick={() => {
                      handleLinkClick("/products");
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    Excellent Cuts
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/heart-and-arrows"
                    onClick={handleClose}
                  >
                    Heart & Arrows
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/commercial-melee"
                    onClick={handleClose}
                  >
                    Commercial Melee
                  </MenuItem>
                </Menu>

                <Button
                  id="diamonds-button"
                  className="cool-button"
                  aria-controls={
                    isDiamondsMenuOpen ? "diamonds-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isDiamondsMenuOpen ? "true" : undefined}
                  onClick={handleDiamondsMenuClick}
                  sx={{
                    color: "#402b66 !important",
                    fontFamily: "Nunito, sans-serif !important",
                    width: "120px",
                  }}
                >
                  Diamonds
                </Button>
                <Menu
                  id="diamonds-menu"
                  className="drop-down"
                  anchorEl={diamondsAnchorEl}
                  open={isDiamondsMenuOpen}
                  onClose={handleDiamondsMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "diamonds-button",
                  }}
                >
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/rounddiamond"
                    onClick={handleDiamondsMenuClose}
                  >
                    Round
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/princessdiamond"
                    onClick={handleDiamondsMenuClose}
                  >
                    Princess
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/peardiamond"
                    onClick={handleDiamondsMenuClose}
                  >
                    Pear
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/marquisediamond"
                    onClick={handleDiamondsMenuClose}
                  >
                    Marquise
                  </MenuItem>
                  <MenuItem
                    className="custom-menu-item"
                    component={Link}
                    to="/baguettediamond"
                    onClick={handleDiamondsMenuClose}
                  >
                    Baguette
                  </MenuItem>
                </Menu>

                <div className="menus">
                  <Button
                    className="menu-item"
                    sx={{ padding: "0", margin: "0", width: "90px" }}
                    onClick={() => {
                      scrollToSection(aboutUsRef);
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    About Us
                  </Button>
                  <Button
                    className="menu-item"
                    sx={{ padding: "0", margin: "0", width: "150px" }}
                    onClick={() => {
                      scrollToSection(managementTeamRef);
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    Management Team
                  </Button>
                  <Button
                    className="menu-item"
                    sx={{ padding: "0", margin: "0", width: "100px" }}
                    onClick={() => {
                      scrollToSection(ourPeopleRef);
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    Our People
                  </Button>
                  <Button
                    className="menu-item"
                    sx={{ padding: "0", margin: "0", width: "120px" }}
                    onClick={() => {
                      scrollToSection(craftsmanshipRef);
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    Craftsmanship
                  </Button>

                  <Button
                    className="menu-item"
                    sx={{ padding: "0", margin: "0", width: "100px" }}
                    onClick={() => {
                      handleLinkClick("/forms");
                      // scrollToSection(formRef);
                      setIsMobileMenuOpen(false); // Close mobile menu
                    }}
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
            </div>
            <div className="hamburger-menu" onClick={handleMobileMenuToggle}>
              <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
              <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
              <div className={`bar ${isMobileMenuOpen ? "open" : ""}`}></div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
