import React, { forwardRef } from "react";
import Slider from "react-slick";
import "./ManagementTeam.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Image1 from "../../assets/Team/maheshbhai.jpg";
import Image2 from "../../assets/Team/lavjibhai.jpg";
import Image3 from "../../assets/Team/bharatbhai.jpg";
import Image4 from "../../assets/Team/dineshbhai.jpg";
import Image6 from "../../assets/Team/sanjaybhai (1).jpg";

const ManagementTeam = forwardRef((props, ref) => {
  const teamMembers = [
    {
      id: 1,
      name: "Maheshbhai Mangukiya",
      designation: "Partner",
      image: Image1,
    },
    {
      id: 2,
      name: "Lavjibhai Mangukiya",
      designation: "Partner",
      image: Image2,
    },
    {
      id: 3,
      name: "Bharatbhai Mangukiya",
      designation: "Partner",
      image: Image3,
    },
    {
      id: 4,
      name: "Dineshbhai Mangukiya",
      designation: "Partner",
      image: Image4,
    },
    {
      id: 5,
      name: "Sanjaybhai Mangukiya",
      designation: "Partner",
      image: Image6,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="management-team" id="management-team" ref={ref}>
      <h1>Management Team</h1>
      <Slider {...settings}>
        {teamMembers.map((card) => (
          <div key={card.id} className="team-member">
            <img width={100} src={card.image} alt={card.designation} />
            <div className="card-content">
              <h3>{card.designation}</h3>
              <p>{card.name}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});

export default ManagementTeam;
