import "./Reputation.css";
import Header from "../Header/Header";

const Reputation = () => {
  // useEffect(() => {
  //     const ball = ballRef.current;

  //     const moveBall = (e) => {
  //         const { clientX, clientY } = e;
  //         ball.style.left = `${clientX}px`;
  //         ball.style.top = `${clientY}px`;
  //     };

  //     window.addEventListener("mousemove", moveBall);

  //     return () => {
  //         window.removeEventListener("mousemove", moveBall);
  //     };
  // }, []);
  return (
    <>
      <Header />
      <div className="reputation">
        {/* <h2>Versatility: Maruti Gems' Expertise in Crafting Diverse Diamonds</h2> */}
        <div className="reputation-text">
          <strong>
            <h2>Range of Diamond Shapes and Sizes</h2>
          </strong>{" "}
          <p>
            {" "}
            Maruti Gems prides itself on its versatility as a diamond
            manufacturing company, offering a wide range of diamond shapes and
            sizes to cater to diverse customer preferences. From classic round
            diamonds to unique shapes like princess, emerald, pear, marquise,
            and baguette, Maruti Gems has the expertise to craft diamonds in
            various shapes and sizes to suit every taste and occasion.
          </p>
          <strong>
            <h2> Customization Options</h2>
          </strong>
          <p>
            {" "}
            In addition to its standard inventory of diamonds, Maruti Gems also
            specializes in customization, allowing customers to create bespoke
            diamond designs tailored to their individual preferences. Whether
            it's a specific shape, size, or quality requirement, Maruti Gems'
            skilled craftsmen can bring any design to life, ensuring that each
            diamond is as unique as the customer it's intended for.
          </p>
          <strong>
            <h2> Expertise in Quality</h2>
          </strong>{" "}
          <p>
            {" "}
            While Maruti Gems offers diamonds in a variety of shapes, sizes, and
            qualities, one thing remains constant: the company's unwavering
            commitment to quality. Whether it's a large, flawless diamond or a
            smaller stone with character, Maruti Gems ensures that each diamond
            meets the highest standards of excellence, meticulously crafted and
            graded to perfection.
          </p>
          <strong>
            <h2> Adaptability to Market Trends</h2>
          </strong>{" "}
          <p>
            {" "}
            As trends in the diamond industry evolve, Maruti Gems remains
            adaptable and responsive to changing market demands. By staying
            attuned to shifting consumer preferences and emerging design trends,
            the company is able to continuously expand its product offerings and
            stay ahead of the curve. This versatility enables Maruti Gems to
            remain relevant and competitive in the global market.
          </p>
          <strong>
            <h2> Catering to Global Customers</h2>
          </strong>{" "}
          <p>
            {" "}
            Maruti Gems' versatility extends beyond its product offerings to its
            ability to cater to a diverse global customer base. With a deep
            understanding of cultural preferences and regional trends, the
            company is able to tailor its diamond offerings to meet the specific
            needs and preferences of customers around the world. Maruti Gems has
            the versatility to fulfill every customer's vision.
          </p>
        </div>
      </div>
    </>
  );
};
export default Reputation;
