import React from "react";
import "./Trust.css";
import Header from "../Header/Header";

const Trust = () => {
  return (
    <>
      <Header />
      <div className="trust">
        <div className="trust-heading">
          <h2>Design and Symbolism</h2>
        </div>
        <div className="trust-text">
          <p>
            The Maruti Gems logo is meticulously designed to embody the
            company's core values of trust and transparency. It features clean
            lines and a modern aesthetic, reflecting the company's commitment to
            excellence and innovation in the diamond industry. The sleek design
            evokes a sense of sophistication and elegance, mirroring the beauty
            of the diamonds Maruti Gems offers to its customers.
          </p>
        </div>
        <div className="trust-heading">
          <h2>Trust and Integrity</h2>
        </div>
        <div className="trust-text">
          <p>
            At the heart of the Maruti Gems logo lies the concept of trust. The
            logo serves as a visual representation of the company's unwavering
            dedication to integrity and honesty in all its business practices.
            By prominently displaying the company's name alongside symbols of
            trust and transparency, the logo instills confidence in customers
            and reinforces Maruti Gems' reputation as a reliable and trustworthy
            partner.
          </p>
        </div>
        <div className="trust-heading">
          <h2>Transparency in Operations</h2>
        </div>
        <div className="trust-text">
          <p>
            The Maruti Gems logo communicates the company's commitment to
            transparency throughout its operations. From diamond sourcing to
            grading and distribution, Maruti Gems ensures that every step of the
            process is conducted with the utmost openness and clarity. The logo
            serves as a reminder of this commitment, inspiring confidence in
            customers and fostering long-lasting relationships built on trust
            and integrity.
          </p>
        </div>
        <div className="trust-heading">
          <h2>Customer Confidence</h2>
        </div>
        <div className="trust-text">
          <p>
            By prominently featuring symbols of trust and transparency in its
            logo, Maruti Gems seeks to instill confidence in its customers. The
            logo serves as a powerful assurance of the company's dedication to
            providing genuine, ethically sourced diamonds of exceptional
            quality. It signifies Maruti Gems' unwavering commitment to meeting
            and exceeding customer expectations, ensuring that every purchase is
            made with confidence and peace of mind.
          </p>
        </div>
        <div className="trust-heading">
          <h2>Global Recognition</h2>
        </div>
        <div className="trust-text">
          <p>
            The Maruti Gems logo has become synonymous with excellence and
            reliability in the diamond industry. Its sleek design and powerful
            symbolism have helped the company gain recognition and acclaim on a
            global scale. Whether displayed on storefronts, websites, or
            marketing materials, the logo serves as a beacon of trust,
            attracting customers from around the world who seek the assurance of
            quality and integrity that Maruti Gems provides.
          </p>
        </div>
      </div>
    </>
  );
};

export default Trust;
