import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import ExcellentCutDiamondImage from "../assets/Products/Excellent.jpeg";
import HeartAndDiamond from "../assets/Products/H&A.jpg";
import CommercialMeleeDiamond from "../assets/Products/CommercialMelle.jpeg";
import Footer from "../Components/Footer/footer";
import Header from "../Components/Header/Header";
import "./Products.css";

const Products = () => {
  const location = useLocation();
  function scrolldiv() {
    const path = location.pathname.split("/")[1];
    var elem = document.getElementById(path);
    elem.scrollIntoView();
  }
  useEffect(() => {
    scrolldiv();
  });
  return (
    <>
      <div id="products" className="diamond-products">
        <Header />
        <div id="excellent-cuts" className="excellent-cut-diamond-container">
          <h1>Excellent Cut Diamond</h1>

          <div className="content-wrapper">
            <div className="image-container">
              <img src={ExcellentCutDiamondImage} alt="Excellent Cut Diamond" />
            </div>
            <div className="text-container">
              <p>
                <h3 style={{ margin: "0" }}>A Masterpiece of Precision</h3>
                <br />
                The Excellent Cut Diamond represents the pinnacle of diamond
                cutting, where every facet is crafted with the utmost precision.
                This level of cut is designed to maximize the diamond's
                brilliance, fire, and scintillation, making it a true
                masterpiece.
              </p>
              <p>
                Diamonds cut to excellent standards are rare and sought after by
                those who appreciate the highest quality. The precise angles and
                proportions of an excellent cut diamond allow it to reflect
                light internally from one facet to another, creating a stunning
                sparkle that is unmatched by any other cut.
              </p>
              <p>
                This perfection in cutting requires the skill of master
                craftsmen who dedicate themselves to achieving the ideal
                symmetry and polish. The result is a diamond that captivates
                with its extraordinary light performance and breathtaking
                beauty, making it the preferred choice for those special
                occasions.
              </p>
            </div>
          </div>
          <div className="pricing">
            <h2>Diamond Details</h2>
            <ul>
              <li>Size Range: 0.90 mm to 3.30 mm</li>
              <li>Clarity: IF</li>
              <li>Color: D to G</li>
              <li>Price Range: $700 - $1500</li>
            </ul>
          </div>
          <div className="inquiry-button-container">
            <Link to="/forms" className="inquiry-button-link">
              <Button
                sx={{ border: "1px solid #402b66" }}
                className="inquiry-button"
              >
                Drop Inquiry
              </Button>
            </Link>
          </div>
        </div>

        <div id="heart-and-arrows" className="heart-and-arrows-container">
          <h1>Heart & Arrows</h1>

          <div className="content-wrapper">
            <div className="image-container">
              <img src={HeartAndDiamond} alt="Heart & Arrows Diamond" />
            </div>
            <div className="text-container">
              <p>
                <h3 style={{ margin: "0" }}>Romance Crafted From Nature</h3>
                <br />
                This is nothing but romanticizing diamonds with pure technicals.
                Essentially a Japanese contribution to the world of diamonds.
                Their penchant for perfection and romanticism led to the
                creation of this cut in the early 1980s. Still a wonder, it
                requires a lot of effort, and not many manufacturers bother with
                such labor.
              </p>
              <p>
                The facets are arranged in such a fashion and precision that the
                play with light enters the realm of romanticism. The face-up
                pattern dispels a set of 8 arrows shooting in eight directions.
                And from the bottom, you find lying eight Hearts…The whole
                visual phenomenon is nothing but a result of pure technical
                perfection. You need to attain a specific degree of angle for
                all eight pavilion facets and then top them with very certain
                angled crown facets and need to create a perfectly placed
                perfectly sized table facet. All this arrangement must also
                carry perfect symmetry. In totality, a Hearts & Arrows Diamond
                is a synonym for perfection.
              </p>
            </div>
          </div>
          <div className="pricing">
            <h2>Diamond Details</h2>
            <ul>
              <li>Size Range: 1.00 mm to 3.30 mm</li>
              <li>Clarity: IF to SI</li>
              <li>Color: D to G</li>
              <li>Price Range: $700 - $2000</li>
            </ul>
          </div>
          <div className="inquiry-button-container">
            <Link to="/forms" className="inquiry-button-link">
              <Button
                sx={{ border: "1px solid #402b66" }}
                className="inquiry-button"
              >
                Drop Inquiry
              </Button>
            </Link>
          </div>
        </div>

        <div id="commercial-melee" className="commercial-melee-container">
          <h1>Commercial Melee</h1>

          <div className="content-wrapper">
            <div className="image-container">
              <img
                src={CommercialMeleeDiamond}
                alt="Commercial Melee Diamond"
              />
            </div>
            <div className="text-container">
              <p>
                <h3 style={{ margin: "0" }}>
                  Affordable Elegance in Every Stone
                </h3>
                <br />
                Commercial Melee Diamonds are the backbone of the diamond
                jewelry industry, providing affordable brilliance and sparkle in
                every piece. These small, round diamonds typically range from
                0.001 to 0.18 carats and are used extensively in pavé settings,
                halos, and accents.
              </p>
              <p>
                Despite their small size, Commercial Melee Diamonds undergo
                rigorous sorting and grading to ensure uniformity in color and
                clarity. They are often sourced in bulk and meticulously matched
                for consistent quality in large-scale jewelry production. These
                diamonds offer an economical solution without compromising the
                aesthetic appeal, making them a popular choice among jewelers
                and consumers alike.
              </p>
            </div>
          </div>
          <div className="pricing">
            <h2>Diamond Details</h2>
            <ul>
              <li>Size Range: 0.001 to 0.18 carats</li>
              <li>Clarity: VS to I3</li>
              <li>Color: D to J</li>
              <li>Price Range: $250 - $1000</li>
            </ul>
          </div>
          <div className="inquiry-button-container">
            <Link to="/forms" className="inquiry-button-link">
              <Button
                sx={{ border: "1px solid #402b66" }}
                className="inquiry-button"
              >
                Drop Inquiry
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;
