import "./Header.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/Logo/MainLogo.png";

const Header = () => {
  // State for Products menu
  const [productsAnchorEl, setProductsAnchorEl] = useState(null);
  const isProductsMenuOpen = Boolean(productsAnchorEl);
  const handleProductsMenuClick = (event) => {
    setProductsAnchorEl(event.currentTarget);
  };
  const handleProductsMenuClose = () => {
    setProductsAnchorEl(null);
  };

  // State for Diamonds menu
  const [diamondsAnchorEl, setDiamondsAnchorEl] = useState(null);
  const isDiamondsMenuOpen = Boolean(diamondsAnchorEl);
  const handleDiamondsMenuClick = (event) => {
    setDiamondsAnchorEl(event.currentTarget);
  };
  const handleDiamondsMenuClose = () => {
    setDiamondsAnchorEl(null);
  };

  // State for Mobile Menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav>
      <div className="Header">
        <img src={Logo} alt="Logo" />
        <div className={`menubar ${mobileMenuOpen ? "open" : ""}`}>
          <Link to="/">
            <Button
              sx={{
                color: "#402b66 !important",
                fontFamily: "Nunito, sans-serif !important",
                width: "200px",
              }}
            >
              HOME
            </Button>
          </Link>

          {/* Products Menu */}
          <div className="products-items">
            <Button
              id="products-button"
              className="cool-button"
              aria-controls={isProductsMenuOpen ? "products-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isProductsMenuOpen ? "true" : undefined}
              onClick={handleProductsMenuClick}
              sx={{
                color: "#402b66 !important",
                fontFamily: "Nunito, sans-serif !important",
                width: "200px",
              }}
            >
              Products
            </Button>
            <Menu
              id="products-menu"
              className="drop-down"
              anchorEl={productsAnchorEl}
              open={isProductsMenuOpen}
              onClose={handleProductsMenuClose}
              MenuListProps={{
                "aria-labelledby": "products-button",
              }}
            >
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/excellent-cuts"
                onClick={handleProductsMenuClose}
              >
                Excellent Cuts
              </MenuItem>

              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/heart-and-arrows"
                onClick={handleProductsMenuClose}
              >
                Heart & Arrows
              </MenuItem>

              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/commercial-melee"
                onClick={handleProductsMenuClose}
              >
                Commercial Melee
              </MenuItem>
            </Menu>
          </div>

          {/* Diamonds Menu */}
          <div className="diamonds-items">
            <Button
              id="diamonds-button"
              className="cool-button"
              aria-controls={isDiamondsMenuOpen ? "diamonds-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isDiamondsMenuOpen ? "true" : undefined}
              onClick={handleDiamondsMenuClick}
              sx={{
                color: "#402b66 !important",
                fontFamily: "Nunito, sans-serif !important",
                width: "200px",
              }}
            >
              Diamonds
            </Button>
            <Menu
              id="diamonds-menu"
              className="drop-down"
              anchorEl={diamondsAnchorEl}
              open={isDiamondsMenuOpen}
              onClose={handleDiamondsMenuClose}
              MenuListProps={{
                "aria-labelledby": "diamonds-button",
              }}
            >
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/rounddiamond"
                onClick={handleDiamondsMenuClose}
              >
                Round
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/princessdiamond"
                onClick={handleDiamondsMenuClose}
              >
                Princess
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/peardiamond"
                onClick={handleDiamondsMenuClose}
              >
                Pear
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/marquisediamond"
                onClick={handleDiamondsMenuClose}
              >
                Marquise
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                component={Link}
                to="/baguettediamond"
                onClick={handleDiamondsMenuClose}
              >
                Baguette
              </MenuItem>
            </Menu>
          </div>
          <Link to="/forms">
            <Button
              sx={{
                color: "#402b66 !important",
                fontFamily: "Nunito, sans-serif !important",
                width: "200px",
              }}
            >
              Contact Us
            </Button>
          </Link>
        </div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileMenu}
          sx={{ display: { md: "none" }, color: "#402b66" }}
        >
          {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
    </nav>
  );
};

export default Header;
