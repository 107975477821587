import React from "react";
import "./App.css";
import HomePage from "./Components/HomePage/HomePage";
import AboutUs from "./Components/AboutUs/Aboutus";
import OurPeople from "./Components/OurPeople/OurPeople";
import ManagementTeam from "./Components/ManagementTeam/ManagementTeam";
import Craftmanship from "./Components/Craftmanship/CRAFTSMANSHIP";
import Form from "./Components/Form/Form";
import Footer from "./Components/Footer/footer";
import { Route, Routes } from "react-router-dom";
import Trust from "./Components/Trust/Trust";
// import Header from './Components/Header/Header';
import Versatility from "./Components/Versatility/Versatility";
import Expertise from "./Components/Expertise/Expertise";
import Reputation from "./Components/Reputation/Reputation";
import RoundDiamond from "./Components/RoundDiamond/RoundDiamond";
import Princess from "./Components/Banner/Princess/Princess";
import Pear from "./Components/Pear/Pear";
import Marquise from "./Components/MarquiseDiamond/Marquise";
import Products from './Pages/Products'
import Forms from './Pages/Forms'
import Baguette from "./Components/Baguette/Baguette";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/ourpeople" element={<OurPeople />} />
        <Route path="/management-team" element={<ManagementTeam />} />
        <Route path="/craftmanship" element={<Craftmanship />} />
        <Route path="/form" element={<Form />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/trust" element={<Trust />} />
        {/* <Route path='/header' element={<Header />} /> */}
        <Route path="/versatility" element={<Versatility />} />
        <Route path="/expertise" element={<Expertise />} />
        <Route path="/reputation" element={<Reputation />} />
        <Route path="/rounddiamond" element={<RoundDiamond />} />
        <Route path="/princessdiamond" element={<Princess />} />
        <Route path="/peardiamond" element={<Pear />} />
        <Route path="/marquisediamond" element={<Marquise />} />
        <Route path="/products" element={<Products />} />
        <Route path="/excellent-cuts" element={<Products />} />
        <Route path="/heart-and-arrows" element={<Products />} />
        <Route path="/commercial-melee" element={<Products />} />
        <Route path="/baguettediamond" element={<Baguette />} />
      </Routes>
    </div>
  );
};

export default App;
