// HomePage.js
import React, { useState, useEffect } from "react";
import "./Banner.css";
import Video from "../../assets/Banner/banner1.mp4";
import Logo from "../../assets/Logo/No speace letter-01.png";
import Navbar from "../Navbar/Navbar";
const Banner = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const videoHeight = document.querySelector(".home").clientHeight;
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > videoHeight * 0.3); // Set sticky when scrolled down 50% of video page
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {isSticky && <Navbar />}
      <div id="banner" className="home">
        <video autoPlay muted loop className="background-video">
          <source src={Video} type="video/mp4" />
        </video>
        <div className="overlay">
          <img src={Logo} alt="Logo" />
          <div className="overlay-text"></div>
        </div>
      </div>
    </>
  );
};

export default Banner;
