import React, { useRef, createContext } from "react";
import Footer from "../Footer/footer";
import OurPeople from "../OurPeople/OurPeople";
import ManagementTeam from "../ManagementTeam/ManagementTeam";
import Craftsmanship from "../Craftmanship/CRAFTSMANSHIP";
import Form from "../Form/Form";
import Banner from "../Banner/Banner";
import AboutUs from "../AboutUs/Aboutus";
import Navbar from "../Navbar/Navbar";

const SectionsRefsContext = createContext();

function HomePage() {
  const sectionsRefs = {
    aboutUsRef: useRef(null),
    managementTeamRef: useRef(null),
    ourPeopleRef: useRef(null),
    craftsmanshipRef: useRef(null),
    formRef: useRef(null),
    footerRef: useRef(null),
  };

  return (
    <SectionsRefsContext.Provider value={sectionsRefs}>
      <Navbar />
      <Banner />
      <AboutUs ref={sectionsRefs.aboutUsRef} />
      <OurPeople ref={sectionsRefs.ourPeopleRef} />
      <ManagementTeam ref={sectionsRefs.managementTeamRef} />
      <Craftsmanship ref={sectionsRefs.craftsmanshipRef} />
      <Form ref={sectionsRefs.formRef} />
      <Footer ref={sectionsRefs.footerRef} />
    </SectionsRefsContext.Provider>
  );
}

export { SectionsRefsContext };
export default HomePage;
