import React, { useState } from "react";
import "./Form.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/footer";
// import Navbar from "../Components/Navbar/Navbar";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setMessage("Invalid email address.");
      setOpenSnackbar(true);
      return;
    }

    if (!validatePhone(formData.phone)) {
      setMessage("Invalid phone number.");
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyJJMbSyB5kWI1OzH4SN4wcc3hEGzUIqw1q6v5D3o_eE4-I8QWiPGhTcKZjT8fmVw_tYw/exec",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formData).toString(),
        }
      );

      if (response.ok) {
        setMessage("Form submitted successfully!");
        setOpenSnackbar(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setMessage("Form submission failed. Please try again.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage("Form submission failed. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Header />
      <div
        className="perent"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="form-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2 className="animated-heading">Contact Us</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="submit">
              <button type="submit">Submit</button>
            </div>
          </form>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={
                message === "Form submitted successfully!" ? "success" : "error"
              }
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </div>
        <iframe
          title="Google Maps Location"
          max-width="auto"
          width="100%"
          height="500px"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=800&amp;height=500&amp;hl=en&amp;q=Mangukiya%20Empire%20Surat+(Mangukiya%20empire)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>{" "}
        <script
          type="text/javascript"
          src="https://embedmaps.com/google-maps-authorization/script.js?id=4593f64816d39008ff40bfb3208d817503c907b0"
        ></script>
      </div>
      <Footer />
    </>
  );
};

export default Form;
