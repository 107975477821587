import React, { useEffect, useState, useRef, forwardRef } from "react";
import OurPeopleVid from "../../assets/Banner/Final.mp4";
import "./OurPeople.css";

const OurPeople = forwardRef((props, ref) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const ourPeopleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    const currentRef = ourPeopleRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ourPeopleRef]);

  useEffect(() => {
    if (isVisible && count < 1000) {
      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= 1000) {
            clearInterval(interval);
            return prevCount;
          }
          return prevCount + 1; 
        });
      }, 3);

      return () => clearInterval(interval);
    }
  }, [isVisible, count]);

  return (
    <div ref={ref} className="ourpeople" id="our-people">
      <div ref={ourPeopleRef} className="our-text">
        <h1>Our People</h1>
        <p>
          We have employees playing a crucial role in the company’s success by
          utilizing their skills and expertise in diamond manufacturing.
        </p>
        <p>
          Their knowledge and experience in grading, cutting, and polishing
          diamonds ensuring that each diamond meets the company’s high standards
          of quality.
        </p>
        <p>
          Additionally, Maruti Gems values its employees by providing a safe and
          inclusive work environment, training opportunities, and fair
          compensation, which motivates employees to deliver exceptional
          performances and contribute to the company’s success.
        </p>
        <div className="counting-container">
          <p className="conting-text">
            {`${count}${count === 1000 ? "+" : ""} `}
            <span> PEOPLES</span>
          </p>
        </div>
      </div>
      <div className="Our-Img">
        <video autoPlay muted loop className="Our-people-video">
          <source src={OurPeopleVid} type="video/mp4" />
        </video>
      </div>
    </div>
  );
});

export default OurPeople;
