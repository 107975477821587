import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.css";
import {
  faCircle,
  faContactCard,
  faDiamond,
  faEnvelope,
  faLink,
  faLocation,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const handleLinkClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };
  return (
    <>
      <div className="Footer" ref={ref}>
        <div className="social-share">
          <div className="text-line">
            <h4>Let's connect us on social media with your networks:</h4>
          </div>
          <div className="social-icons-share">
            <a
              href="https://www.linkedin.com/shareArticle?url=https://marutigems.co.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="linkedin-icon">
                <FontAwesomeIcon icon={faLinkedin} />
              </div>
            </a>
            <a
              href="https://wa.me/?text=Check%20out%20this%20website:%20https://marutigems.co.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="whatsapp-icon">
                <FontAwesomeIcon icon={faWhatsapp} />
              </div>
            </a>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://marutigems.co.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="facebook-icon">
                <FontAwesomeIcon icon={faFacebook} />
              </div>
            </a>
            <a
              href="https://twitter.com/intent/tweet?https://marutigems.co.in-url&text=Check%20out%20this%20website-https://marutigems.co.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="twitter-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
            </a>
          </div>
        </div>
        <div className="footer-content">
          <div className="about">
            <h1>
              <FontAwesomeIcon icon={faDiamond} />
              MARUTI GEMS
            </h1>
            <h2>
              Maruti Gems, Surat's premier diamond firm, epitomizes brilliance
              and excellence. With unparalleled craftsmanship and exquisite
              designs, we illuminate the world with the allure of diamonds.{" "}
            </h2>
          </div>
          <div className="products">
            <h1>
              <FontAwesomeIcon icon={faCircle} />
              DIAMONDS
            </h1>

            <h3 onClick={() => handleLinkClick("/rounddiamond")}>ROUND</h3>

            <h3 onClick={() => handleLinkClick("/peardiamond")}>PEAR</h3>

            <h3 onClick={() => handleLinkClick("/marquisediamond")}>
              MARQUISE
            </h3>

            <h3 onClick={() => handleLinkClick("/princessdiamond")}>
              PRINCESS
            </h3>

            <h3 onClick={() => handleLinkClick("/baguettediamond")}>
              BAGUETTE
            </h3>
          </div>
          <div className="quick-links">
            <h1>
              <FontAwesomeIcon icon={faLink} />
              QUICK LINKS
            </h1>

            <Link to="/heart-and-arrows">
              <h3>HEARTS & ARROW</h3>
            </Link>
            <Link to="/excellent-cuts">
              <h3>EXCELLENT CUTS</h3>
            </Link>
            <Link to="/commercial-melee">
              <h3>COMMERCIAL MELLE</h3>
            </Link>

            <h3 onClick={() => handleLinkClick("/forms")}>CONTACT US</h3>
          </div>
          <div className="contact-us">
            <h1>
              <FontAwesomeIcon icon={faContactCard} />
              CONTACT
            </h1>

            <h3>
              <h4>
                <FontAwesomeIcon icon={faLocation} />
                Manufacturing Head Office
              </h4>
              <h5>
                Plot No.01, 4th And 3rd Floor, Mangukiya Empire, Opp.Sar
                Corporate Center, Near Mehta Petrol Pump, Katargam, Surat,
                Gujarat-395004.
              </h5>
            </h3>
            <h2>
              <FontAwesomeIcon icon={faMobile} />
              0261- 2480063{" "}
            </h2>
            <h2>
              {" "}
              <FontAwesomeIcon icon={faEnvelope} />
              info@marutigems.co.in
            </h2>

            <div className="social">
              <a
                href="https://www.instagram.com/maruti._.gems/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="in" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61560936627069"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="fb" />
              </a>
              <a
                href="https://www.linkedin.com/in/maruti-gems/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className="ln" />
              </a>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <p>© All Rights Reserved By Mangukiya Empire </p>
        </div>
      </div>
    </>
  );
});
export default Footer;
